import {
  NewExamDetailParams,
  CbexMomentExam,
  PlanningActivity,
} from "@cbex/data-access";
import { ExamDetailsProp } from "../forms/ExamDetailsForm";

export const getNewExamDetailsDefaultValues = (input?: NewExamDetailParams) => {
  const defaults: ExamDetailsProp = {
    locationID: input?.locationId || "",

    publicExam: input?.publicExam || false,
    publicExamSeats: input?.publicExamSeats || 0,
    activityTypes:
      (input &&
        input.activityTypeIDs.map((id) => ({
          id,
          name: "",
        }))) ||
      [],
    moment: {
      momentID: "",
      startDate: input?.startDate || undefined,
      locationIds: input?.locationId ? [input.locationId] : [],
      notes: input?.notes || "",
    },
    resources:
      input?.resources.map((res) => ({
        resourceID: res,
        name: "",
      })) || [],
  };
  return defaults;
};

export const getExamDetailsDefaultValues = (
  input: CbexMomentExam
): ExamDetailsProp => {
  const defaults: ExamDetailsProp = {
    publicExam:
      (input?.moment?.properties && input?.moment?.properties["publicExam"]) ||
      false,
    isPublicExam:
      (input?.moment?.properties && input?.moment?.properties["publicExam"]) ||
      false,
    totalCandidates: input.totalCandidates,
    totalPublicCandidates: input.totalPublicCandidates,
    initialCandidatesAmount: (input.candidates && input.candidates.length) || 0,
    publicExamSeats:
      (input?.moment?.properties &&
        input?.moment?.properties["publicExamSeats"]) ||
      undefined,
    activityTypes:
      (input &&
        input.activities &&
        input.activities.length > 0 &&
        input.activities.map((act: PlanningActivity) => act.activityType)) ||
      [],
    locationID:
      (input &&
        input.moment &&
        input.moment.locationIds &&
        input.moment.locationIds.length > 0 &&
        input.moment.locationIds[0]) ||
      "",
    moment: input && input.moment && input.moment,
    resources:
      (input &&
        input.moment &&
        input.moment.resources &&
        input.moment.resources.length > 0 &&
        input.moment.resources.map((res) => ({
          resourceID: res,
          name: "",
        }))) ||
      [],
    candidates:
      input && input.candidates && input.candidates.length > 0
        ? input.candidates
        : [],
    activities:
      input && input.activities && input.activities.length > 0
        ? input.activities
        : [],
  };

  return defaults;
};
