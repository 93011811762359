import React, { Fragment } from "react";
import { useTranslations } from "next-intl";
import {
  Stack,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
} from "@mui/material";
import { CustomTypographyComponent } from "@cbex/ui/text";
import {
  CustomTextfieldController,
  CustomTextfieldTableController,
} from "@cbex/form/textfield";
import DeleteIcon from "@mui/icons-material/Delete";
import { styled } from "@mui/system";
import { CustomDatePickerTableController } from "@cbex/form/datepicker";
import {
  CbexPortalCandidatesByMomentIdRow,
  CandidatesByActivityTypeIDsAndDateRow,
} from "@cbex/data-access";
import { UseFormReturn, useFieldArray } from "react-hook-form";
import { SingleActivityTypeAutoComplete } from "../composites/Refactor/SingleActivityTypeAutoComplete";
import { useWindowDimensions } from "@cbex/utils/hooks";
import ImportCandidateModal from "../composites/ImportCandidateModal";
import { ExamDetailsProp } from "./ExamDetailsForm";
import dayjs from "dayjs";
import { AppContext, AppContextState } from "@cbex/utils/context";
import {
  getActivitiesImmutable,
  getCandidatesImmutable,
} from "../utils/getExamEditState";

interface CandidateDetailsFormNewProps {
  form: UseFormReturn<ExamDetailsProp>;
  editAllowed: boolean;
  initialAmountCandidates: number;
}
export const CandidateDetailsFormNew = ({
  form,
  editAllowed,
  initialAmountCandidates,
}: CandidateDetailsFormNewProps) => {
  const { control, getValues, setValue } = form;
  const windowSize = useWindowDimensions();
  const [openImportModal, setOpenImportModal] = React.useState(false);

  //@ts-ignore
  const { localAppState }: { localAppState: AppContextState } =
    React.useContext(AppContext);

  const canImportCandidates =
    localAppState?.userData?.organization?.properties?.useImportCandidates ||
    false;

  const StyledTableCell = styled(TableCell)({
    paddingTop: 12,
    paddingBottom: 6,
    paddingLeft: 2,
    paddingRight: 2,
    verticalAlign: "top",
    borderBottom: "0",
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "candidates",
  });

  const t = useTranslations();

  const [activityTypeOptions, setActivityTypeOptions] = React.useState([]);

  React.useEffect(() => {
    const activityTypes = getValues("activityTypes");
    if (activityTypes) {
      setActivityTypeOptions(
        activityTypes.map((act) => ({
          ...act,
          disabled: act?.settings?.customerRegistrationTime
            ? act.settings.customerRegistrationTime >
              dayjs(getValues("moment.startDate")).diff(dayjs(), "minutes")
            : false,
        }))
      );
    }
  }, [getValues]);

  const handleRowAdded = () => {
    const allCandidates = getValues("candidates");
    let lastCandidate: CbexPortalCandidatesByMomentIdRow = {
      activityTypeID: "",
      candidateID: "",
      lastName: "",
      dateOfBirth: undefined,
      infix: "",
      initials: "",
      emailAddress: "",
    };
    if (allCandidates && allCandidates.length > 0) {
      const element = allCandidates[allCandidates.length - 1];
      lastCandidate = {
        activityTypeID:
          activityTypeOptions.find(
            (act) =>
              act.activityTypeID === element.activityTypeID && !act.disabled
          )?.activityTypeID || "",
        lastName: "",
        candidateID: "",
        dateOfBirth: undefined,
        emailAddress: "",
        infix: "",
        initials: "",
      };
    }

    append(lastCandidate);
  };

  const onDeleteClickHandler = React.useCallback(
    (itemIndex: number) => {
      remove(itemIndex);
    },
    [remove]
  );

  const immutableActivities = getActivitiesImmutable(
    getValues("activityTypes"),
    getValues("moment.startDate"),
    true
  );
  const CustomRow = React.useMemo(
    () =>
      fields &&
      fields.length > 0 &&
      fields.map(
        (row: CbexPortalCandidatesByMomentIdRow & { id: string }, index) => {
          const isImmutable = immutableActivities.some(
            (activity) => activity.activityTypeID === row.activityTypeID
          );
          return (
            <Fragment key={row.id}>
              <TableRow>
                <StyledTableCell align="left">
                  <SingleActivityTypeAutoComplete
                    width={"auto"}
                    sx={{ minWidth: 300 }}
                    control={control}
                    controlDisabled={!editAllowed || isImmutable}
                    name={`candidates.${index}.activityTypeID`}
                    defaultValue={row.activityTypeID}
                    disabledPlanningActivities={immutableActivities}
                    inputPlanningActivityTypes={activityTypeOptions}
                  />
                </StyledTableCell>
                <StyledTableCell align="left">
                  <CustomTextfieldTableController
                    sx={{ width: "100%" }}
                    name={`candidates.${index}.initials`}
                    required
                    control={control}
                    controlDisabled={!editAllowed || isImmutable}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <CustomTextfieldController
                    sx={{ width: "100%" }}
                    controlDisabled={!editAllowed || isImmutable}
                    name={`candidates[${index}].infix`}
                    control={control}
                  />
                </StyledTableCell>
                <StyledTableCell align="left">
                  <CustomTextfieldTableController
                    sx={{ minWidth: 180, width: "100%" }}
                    controlDisabled={!editAllowed || isImmutable}
                    name={`candidates.${index}.lastName`}
                    required
                    control={control}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <CustomDatePickerTableController
                    width={"100%"}
                    sx={{ minWidth: 150, width: "100%" }}
                    controlDisabled={!editAllowed || isImmutable}
                    label={""}
                    control={control}
                    required
                    name={`candidates.${index}.dateOfBirth`}
                    views={["year", "month", "day"]}
                    incomingError={t("labels.required")}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <CustomTextfieldController
                    sx={{ minWidth: 300, width: "100%" }}
                    controlDisabled={!editAllowed || isImmutable}
                    name={`candidates.${index}.emailAddress`}
                    control={control}
                  />
                </StyledTableCell>

                <StyledTableCell align="left">
                  {!isImmutable && (
                    <IconButton
                      disabled={!editAllowed}
                      onClick={() => onDeleteClickHandler(index)}
                    >
                      <DeleteIcon
                        color={editAllowed ? "primary" : "disabled"}
                        titleAccess={t("labels.removeRow")}
                      />
                    </IconButton>
                  )}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    paddingTop: 0,
                    paddingRight: 0.5,
                    paddingLeft: 0.5,
                    paddingBottom: 0.5,
                  }}
                  align="left"
                  colSpan={6}
                >
                  <CustomTextfieldController
                    sx={{ minWidth: 220, width: "100%" }}
                    id="outlined-adornment-note"
                    label={t("labels.note")}
                    controlDisabled={!editAllowed || isImmutable}
                    multiline
                    control={control}
                    name={`candidates.${index}.notes`}
                  />
                </TableCell>
              </TableRow>
            </Fragment>
          );
        }
      ),
    [StyledTableCell, control, fields, getValues, onDeleteClickHandler, t]
  );

  const onImportModalDataAdded = (
    inputCandidates: CandidatesByActivityTypeIDsAndDateRow[]
  ) => {
    setOpenImportModal(false);

    const allCandidates = getValues("candidates");
    const newCanidates = inputCandidates.map((candidateItem) => ({
      activityTypeID: candidateItem.activityTypeID,
      candidateID: "",
      lastName: candidateItem.lastName,
      dateOfBirth: candidateItem.dateOfBirth || undefined,
      infix: candidateItem.infix || "",
      initials: candidateItem.initials || "",
      emailAddress: candidateItem.emailAddress || "",
    }));

    setValue("candidates", allCandidates.concat(newCanidates));
  };

  const calculateTotalPublicCandidates = () => {
    const totalSeatsStillAvailable =
      getValues("moment.properties.examCapacity") -
      getValues("totalCandidates");
    const totalPublicSeatsAvailable =
      getValues("moment.properties.publicExamSeats") -
      getValues("totalPublicCandidates");

    if (initialAmountCandidates === fields.length) {
      if (getValues("isPublicExam")) {
        if (
          totalPublicSeatsAvailable > 0 &&
          totalSeatsStillAvailable >= totalPublicSeatsAvailable
        ) {
          return totalPublicSeatsAvailable;
        } else if (
          totalPublicSeatsAvailable > 0 &&
          totalSeatsStillAvailable < totalPublicSeatsAvailable
        ) {
          return totalSeatsStillAvailable;
        } else {
          return -1;
        }
      } else {
        return totalSeatsStillAvailable;
      }
    } else {
      if (getValues("isPublicExam")) {
        if (
          totalPublicSeatsAvailable > 0 &&
          totalSeatsStillAvailable >= totalPublicSeatsAvailable
        ) {
          return (
            totalPublicSeatsAvailable + initialAmountCandidates - fields.length
          );
        } else if (
          totalPublicSeatsAvailable > 0 &&
          totalSeatsStillAvailable < totalPublicSeatsAvailable
        ) {
          return (
            totalSeatsStillAvailable + initialAmountCandidates - fields.length
          );
        } else {
          return -1;
        }
      } else {
        return (
          totalSeatsStillAvailable + initialAmountCandidates - fields.length
        );
      }
    }
  };

  return (
    <Stack justifyContent={"space-around"}>
      <Stack
        direction={"row"}
        padding={2}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <CustomTypographyComponent variant="body1" fontWeight={"bold"} my={4}>
          {t("labels.seatsRemaining") + ": "}
          {calculateTotalPublicCandidates()}
        </CustomTypographyComponent>
        <Stack spacing={3} direction={"row"}>
          {canImportCandidates && (
            <Button
              disabled={!editAllowed || calculateTotalPublicCandidates() <= 0}
              onClick={() => setOpenImportModal(true)}
              variant="contained"
            >
              {t("common.importCandidates")}
            </Button>
          )}
          {immutableActivities.length !== activityTypeOptions.length && (
            <Button
              disabled={!editAllowed || calculateTotalPublicCandidates() <= 0}
              onClick={() => {
                handleRowAdded();
              }}
              variant="contained"
            >
              {t("labels.addRow")}
            </Button>
          )}
        </Stack>
      </Stack>
      <Paper>
        <Stack paddingBottom={4} paddingLeft={2}>
          <TableContainer
            sx={{
              maxHeight: windowSize.height - 480,
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <Table aria-label="simple table" stickyHeader>
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">
                    {t("labels.activity")}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {t("labels.initials")}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {t("labels.infix")}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {t("labels.lastName")}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {t("labels.dob")}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {t("labels.emailAddress")}
                  </StyledTableCell>

                  <StyledTableCell width={20} align="left"></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>{CustomRow}</TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Paper>

      {openImportModal && (
        <ImportCandidateModal
          modalOpen={openImportModal}
          handleClose={() => setOpenImportModal(false)}
          handleCloseWithData={onImportModalDataAdded}
          activityTypeIDs={getValues("activityTypes").map(
            (act) => act.activityTypeID
          )}
        />
      )}
    </Stack>
  );
};

export default CandidateDetailsFormNew;
