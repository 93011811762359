import { AlertColor, IconButton, Stack } from "@mui/material";
import React from "react";
import { getExamDetailsDefaultValues } from "../utils/ExamDetailsDefaultValues";
import {
  GetActivityTypeDocument,
  PlanningActivityType,
  ResourceListByActivityTypeIdDocument,
  ResourcesByActivityTypeIdAndEmployeeIdRow,
  StoreCandidateParams,
  StoreCandidatesDocument,
  UpdateExamDocument,
} from "@cbex/data-access";
import { Resolver, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslations } from "next-intl";
import SaveIcon from "@mui/icons-material/Save";
import { CustomTab, TabPanel } from "@cbex/ui/tabs";
import ExamDetailsForm from "../forms/ExamDetailsForm";
import CandidateDetailsFormNew from "../forms/CandidateDetailsFormNew";
import { useMutation, useQuery } from "@apollo/client";
import _ from "lodash";
import { removeInArray } from "../utils/examFormUtils";
import { SnackBar } from "@cbex/ui/snack-bar";
import {
  getActivitiesImmutable,
  getExamEditState,
  getActivitiesCreationNotAllowed,
} from "../utils/getExamEditState";
import { ExamDetailsProp } from "../forms/ExamDetailsForm";
interface ExamDetailsCompositeProps {
  examData: ExamDetailsProp;
  refetchOverviewData: () => void;
}

const ExamDetailsComposite = ({
  examData,
  refetchOverviewData,
}: ExamDetailsCompositeProps) => {
  const t = useTranslations();
  const [tabValue, setTabValue] = React.useState(0);
  const [snackBar, setSnackBar] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState<AlertColor>("success");
  const [resources, setResources] = React.useState<
    ResourcesByActivityTypeIdAndEmployeeIdRow[]
  >([]);
  const [planningActivityTypes, setPlanningActivityTypes] = React.useState<
    PlanningActivityType[]
  >([]);
  // left hand side  and also part of the search
  const [localPlanningActivityTypes, setLocalPlanningActivityTypes] =
    React.useState<PlanningActivityType[]>([]);
  // right hand side for dispalying
  const [selectedActivityTypes, setSelectedActivityTypes] = React.useState<
    PlanningActivityType[]
  >([]);
  const isEditable = getExamEditState(examData);

  const onUpdateMutationComplete = () => {
    setTimeout(() => {
      setSnackBarMessage(t("feedback.updateSuccess"));
      setSnackBar(true);
      setSeverity("success");
      refetchOverviewData();
    }, 1000);
  };
  const [updateExam] = useMutation(UpdateExamDocument, {
    onCompleted: onUpdateMutationComplete,
  });

  const handleOnStoreMutationCompleted = function () {
    setTimeout(() => {
      setSnackBarMessage(t("feedback.updateSuccess"));
      setSnackBar(true);
      setSeverity("success");
    }, 1000);
    setTimeout(() => {
      refetchOverviewData();
    }, 1000);
  };
  const [storeCandidates] = useMutation(StoreCandidatesDocument, {
    onCompleted: handleOnStoreMutationCompleted,
  });

  const handleTabValue = (val: number) => {
    setTabValue(val);
  };

  const examSchema = yup.object().shape({
    moment: yup
      .object()
      .shape({
        startDate: yup.date().required(t("labels.required")),
        notes: yup.string().nullable(),
        momentID: yup.string().required(),
        location: yup.object().required(),
      })
      .required(),
    locationID: yup.string().required(t("labels.required")),
    activityTypes: yup
      .array()
      .test("activityTypes", t("labels.required"), (val, context) => {
        const { activityTypes } = context.parent;
        const startDate = context.parent.moment.startDate;
        if (activityTypes && activityTypes.length > 0) {
          const isImmutableActivity = [];
          const isNotAllowedCreationActivity = [];
          const immutableActivities = getActivitiesImmutable(
            planningActivityTypes,
            startDate,
            false
          );
          const notAllowedCreationActivities = getActivitiesCreationNotAllowed(
            planningActivityTypes,
            startDate
          );
          activityTypes.forEach((selectedActivity) => {
            isImmutableActivity.push(
              immutableActivities.some(
                (activity) =>
                  activity.activityTypeID === selectedActivity.activityTypeID
              )
            );
          });
          activityTypes.forEach((selectedActivity) => {
            isNotAllowedCreationActivity.push(
              notAllowedCreationActivities.some(
                (activity) =>
                  activity.activityTypeID === selectedActivity.activityTypeID
              )
            );
          });
          if (
            isImmutableActivity.includes(true) ||
            isNotAllowedCreationActivity.includes(true)
          ) {
            return context.createError({
              path: `activityTypeIDs`,
              message: t("feedback.immutableActivitySelectedForExam"),
            });
          } else return context.resolve(true);
        } else if (activityTypes && activityTypes.length <= 0) {
          return context.createError({
            path: `activityTypeIDs`,
            message: t("labels.required"),
          });
        }
      }),
    resources: yup
      .array()
      .required()
      .test("activityTypes", t("labels.required"), (val, context) => {
        const { activityTypes } = context.parent;
        if (
          activityTypes.length > 0 &&
          activityTypes[0]?.settings?.allowSelectResource?.toString() === "true"
        ) {
          return val.length > 0
            ? true
            : context.createError({ message: t("labels.required") });
        }
        return true;
      }),
    candidates: yup.array().of(
      yup.object().shape({
        activityTypeID: yup.string().required(),
        lastName: yup.string().required(t("labels.required")),
        initials: yup.string().required(t("labels.required")),
        infix: yup.string().nullable(),
        dateOfBirth: yup.date().required(t("labels.required")),
        emailAddress: yup.string().email(t("labels.invalidEmail")).nullable(),
        notes: yup.string().nullable(),
      })
    ),
  }) as yup.ObjectSchema<ExamDetailsProp>;

  const form = useForm<ExamDetailsProp>({
    mode: "onSubmit",
    defaultValues: getExamDetailsDefaultValues(examData),
    resolver: yupResolver(examSchema) as unknown as Resolver<ExamDetailsProp>,
  });

  const handleClose = () => {
    setSnackBar(false);
  };

  const { getValues, handleSubmit, control } = form;

  const activityTypeChanges = useWatch({
    control: control,
    name: "activityTypes",
  });

  const { data: activityTypeData, loading: activityTypeDataLoading } = useQuery(
    GetActivityTypeDocument,
    {
      variables: {},
      fetchPolicy: "network-only",
    }
  );

  const { data: resourcesData, loading: resourcesLoading } = useQuery(
    ResourceListByActivityTypeIdDocument,
    {
      variables: {
        activityTypeID:
          (getValues("activityTypes") &&
            getValues("activityTypes").map((item) => item.activityTypeID)) ||
          [],
      },
      fetchPolicy: "network-only",
      skip:
        !getValues("activityTypes") || getValues("activityTypes").length === 0,
    }
  );

  React.useEffect(() => {
    if (
      !activityTypeDataLoading &&
      activityTypeData &&
      activityTypeData.ActivityTypes &&
      activityTypeData.ActivityTypes.length > 0
    ) {
      const newActivityType = activityTypeData.ActivityTypes.map(
        (item: PlanningActivityType) => ({
          activityTypeID: item.activityTypeID,
          name: item.name,
          competenceID: item.competenceID,
          settings: item.settings,
        })
      );
      setPlanningActivityTypes([...newActivityType]);
      setLocalPlanningActivityTypes(
        removeInArray([...newActivityType], getValues("activityTypes"))
      );
    } else {
      setPlanningActivityTypes([]);
      setLocalPlanningActivityTypes([]);
    }
  }, [activityTypeData, activityTypeDataLoading, getValues]);

  React.useEffect(() => {
    if (
      resourcesData &&
      resourcesData.ResourceListByActivityTypeID &&
      resourcesData.ResourceListByActivityTypeID.length > 0
    ) {
      const resourceList: ResourcesByActivityTypeIdAndEmployeeIdRow[] =
        resourcesData.ResourceListByActivityTypeID.map((resourceInput) => ({
          name: resourceInput.name,
          resourceID: resourceInput.resourceID,
          employeeID: "",
        }));

      setResources(resourceList);
    } else {
      setResources([]);
    }
  }, [resourcesData, resourcesLoading]);

  React.useEffect(() => {
    if (activityTypeChanges) {
      setSelectedActivityTypes(activityTypeChanges);
    }
  }, [activityTypeChanges]);

  const candidateDetailsPanel = React.useMemo(
    () => (
      <TabPanel value={tabValue} index={1}>
        <CandidateDetailsFormNew
          editAllowed={isEditable}
          form={form}
          initialAmountCandidates={
            examData?.candidates ? examData.candidates?.length : 0
          }
        />
      </TabPanel>
    ),
    [form, isEditable, tabValue]
  );

  const handleOnError = (errors: any) => {
    if (errors) {
      setSnackBar(true);
      setSeverity("error");
      setSnackBarMessage(t("feedback.missingValues"));
    }
  };

  const handleExamUpdated = (examDetails: ExamDetailsProp) => {
    if (!_.isEmpty(form.formState.errors)) {
      setSnackBarMessage(t("feedback.missingValues"));
      setSnackBar(true);
      setSeverity("error");
      return;
    }

    if (
      examDetails.locationID === undefined ||
      examDetails.locationID.length === 0 ||
      examDetails.moment.startDate === undefined ||
      examDetails.moment.startDate === null
    ) {
      setSnackBarMessage(t("feedback.missingValues"));
      setSnackBar(true);
      setSeverity("error");
      return;
    } else if (
      examDetails &&
      examDetails.activityTypes &&
      examDetails.activityTypes.length > 0
    ) {
      updateExam({
        variables: {
          input: {
            notes: examDetails.moment.notes,
            momentID: examDetails.moment.momentID,
            activityTypeIDs: examDetails.activityTypes.map(
              (act) =>
                (act.activityTypeID && act.activityTypeID) || act.activityTypeID
            ),
            startDate: examDetails.moment.startDate,
            locationId: examDetails.locationID,
            resources: examDetails.resources.map((res) => res.resourceID),
          },
        },
      }).then(() => {
        const mappedCandidates: StoreCandidateParams[] = [];
        if (examDetails.candidates && examDetails.candidates.length > 0) {
          examDetails.candidates.forEach((element) => {
            mappedCandidates.push({
              activityTypeID: element.activityTypeID,
              candidateID: (element.candidateID && element.candidateID) || "0",
              dateOfBirth: element.dateOfBirth,
              lastName: element.lastName,
              emailAddress:
                (element.emailAddress && element.emailAddress) || "",
              infix: element.infix,
              initials: element.initials,
              notes: element.notes,
            });
          });

          storeCandidates({
            variables: {
              input: mappedCandidates,
              momentID: examData.moment.momentID,
            },
          }).catch(() => {
            setTimeout(() => {
              setSnackBarMessage(t("feedback.storeFailed"));
              setSnackBar(true);

              setSeverity("error");
            }, 1000);
          });
        }
      });
    } else {
      setSnackBarMessage(t("feedback.missingValues"));
      setSnackBar(true);
      setSeverity("error");
    }
  };

  return (
    <Stack id="ExamDetailsComposite" height={"100%"}>
      <Stack alignItems={"flex-end"}>
        <IconButton
          sx={{ width: 15 }}
          disabled={!isEditable}
          type="submit"
          onClick={handleSubmit(
            () => handleExamUpdated(getValues()),
            (err: any) => handleOnError(err)
          )}
        >
          <SaveIcon color={isEditable ? "primary" : "disabled"} />
        </IconButton>
      </Stack>

      <Stack height={"100%"}>
        <CustomTab
          id="ExamTabs"
          onValueChanged={handleTabValue}
          tabPanels={[t("labels.examDetails"), t("labels.candidateDetails")]}
        >
          <form style={{ height: "100%" }}>
            <TabPanel value={tabValue} index={0}>
              <ExamDetailsForm
                allActivities={planningActivityTypes}
                editAllowed={isEditable}
                allResources={resources}
                locationDetails={examData.moment && examData.moment.location}
                currentSelectedActivity={selectedActivityTypes}
                filteredActivities={localPlanningActivityTypes}
                form={form}
              />
            </TabPanel>
            {candidateDetailsPanel}
          </form>
        </CustomTab>
      </Stack>
      {snackBar && (
        <SnackBar
          openState={snackBar}
          severity={severity}
          message={snackBarMessage}
          onClose={handleClose}
        ></SnackBar>
      )}
    </Stack>
  );
};

export default ExamDetailsComposite;
