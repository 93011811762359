import React from "react";
import { useTranslations } from "next-intl";
import {
  Stack,
  FormControl,
  Paper,
  ListItem,
  List,
  IconButton,
  Grid,
  Card,
  CardHeader,
  Divider,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Button,
  ListItemSecondaryAction,
  Tooltip,
  ListItemButton,
} from "@mui/material";
import { CustomTextfieldController } from "@cbex/form/textfield";
import { CustomDateTimePickerController } from "@cbex/form/datetimepicker";
import { UseFormReturn, useWatch } from "react-hook-form";
import { AppContext } from "@cbex/utils/context";
import ExamResourcesAutoComplete from "../composites/Refactor/ExamResourcesAutoComplete";
import {
  ResourcesByActivityTypeIdAndEmployeeIdRow,
  PlanningActivityType,
  RelationAddress,
  CbexMomentExam,
} from "@cbex/data-access";
import { useWindowDimensions } from "@cbex/utils/hooks";
import DeleteIcon from "@mui/icons-material/Delete";
import { CustomTextField } from "@cbex/ui/input";
import { CustomDialog } from "@cbex/ui/modal";
import { CustomTypographyComponent } from "@cbex/ui/text";
import { useTheme } from "@cbex/utils/theme";
import { intersection, not, removeInArray } from "../utils/examFormUtils";
import {
  getActivitiesImmutable,
  getActivitiesCreationNotAllowed,
} from "../utils/getExamEditState";
import { CustomCheckboxController } from "@cbex/form/checkbox";
import { CustomNumberController } from "@cbex/form/numberfield";

export type ExamDetailsProp = CbexMomentExam & {
  resources: ResourcesByActivityTypeIdAndEmployeeIdRow[];
  locationID: string;
  activityTypes: PlanningActivityType[];
  publicExam: boolean;
  publicExamSeats: number;
  initialCandidatesAmount?: number;
};

interface ExamDetailsFormProps {
  form: UseFormReturn<ExamDetailsProp>;
  allActivities: PlanningActivityType[];
  allResources: ResourcesByActivityTypeIdAndEmployeeIdRow[];
  currentSelectedActivity: PlanningActivityType[];
  filteredActivities: PlanningActivityType[];
  locationDetails?: RelationAddress;
  editAllowed: boolean;
}

const ExamDetailsForm = ({
  form,
  allActivities,
  allResources,
  currentSelectedActivity,
  filteredActivities,
  locationDetails,
  editAllowed,
}: ExamDetailsFormProps) => {
  const t = useTranslations();
  const windowSize = useWindowDimensions();
  const theme = useTheme();
  const { control, getValues, setValue } = form;
  const [searchText, setSearchText] = React.useState<string | null>(null);
  //@ts-ignore
  const { localAppState } = React.useContext(AppContext);
  const { enableUseOpenExam } =
    localAppState?.userData?.organization?.properties;
  const [planningActivityTypes, setPlanningActivityTypes] = React.useState<
    PlanningActivityType[]
  >([]);
  // left hand side  and also part of the search
  const [localPlanningActivityTypes, setLocalPlanningActivityTypes] =
    React.useState<PlanningActivityType[]>(filteredActivities || []);
  // right hand side for displaying
  const [selectedActivityTypes, setSelectedActivityTypes] = React.useState<
    PlanningActivityType[]
  >(currentSelectedActivity || []);

  const isPublicExam = getValues("publicExam");
  const startDateValue = useWatch({ control, name: "moment.startDate" });

  React.useEffect(() => {
    if (allActivities && allActivities.length > 0) {
      setPlanningActivityTypes(allActivities);
    }
  }, [allActivities]);

  React.useEffect(() => {
    if (filteredActivities && filteredActivities.length > 0) {
      setLocalPlanningActivityTypes(filteredActivities);
    }
  }, [filteredActivities]);

  React.useEffect(() => {
    if (currentSelectedActivity && currentSelectedActivity.length > 0) {
      setSelectedActivityTypes(currentSelectedActivity);
    }
  }, [currentSelectedActivity]);

  const [checked, setChecked] = React.useState<readonly any[]>([]);
  const [
    currentDeletePendingActivityType,
    setCurrentDeletePendingActivityType,
  ] = React.useState<PlanningActivityType[]>([]);
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] =
    React.useState(false);

  const leftChecked =
    planningActivityTypes && planningActivityTypes.length > 0
      ? intersection(checked, planningActivityTypes)
      : [];

  const handleToggle = (value: any) => () => {
    if (checked.length > 0) {
      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      setChecked(newChecked);
    } else {
      setChecked([value]);
    }
  };

  const handleCancel = () => {
    setOpenDeleteConfirmDialog(false);
    setCurrentDeletePendingActivityType(null);
  };

  const handleCheckedRight = async () => {
    const newSelected = selectedActivityTypes.concat(...leftChecked);
    setSelectedActivityTypes(newSelected);
    setValue("activityTypes", newSelected);
    const newLocal = removeInArray(planningActivityTypes, newSelected);
    setLocalPlanningActivityTypes(newLocal);
    setChecked(not(checked, leftChecked));
    setSearchText(null);
  };

  const filter = (incomingVaL: string) => {
    setSearchText(incomingVaL);

    const filteredPlanningActivityTypes = planningActivityTypes.filter(
      (val: PlanningActivityType) =>
        val.name.toLowerCase().includes(incomingVaL)
    );
    const remainingActivities = removeInArray(
      filteredPlanningActivityTypes,
      selectedActivityTypes
    );
    setLocalPlanningActivityTypes(remainingActivities);
  };

  const handleDisabledFilter = (option, inputArray): boolean => {
    if (!startDateValue) {
      // We need to select a start date first before any activity can be selected
      return true;
    }

    if (
      isActivityImmutable(option.activityTypeID) ||
      isActivityCreationNotAllowed(option.activityTypeID)
    ) {
      return true;
    }

    if (inputArray.length <= 0) {
      return false;
    }

    if (option) {
      const findCometenceID = inputArray[0].competenceID;

      if (findCometenceID === option.competenceID) return false;
      else {
        return true;
      }
    }
  };

  const isActivityImmutable = (activityTypeID: string): boolean =>
    getActivitiesImmutable(planningActivityTypes, startDateValue).some(
      (activity) => activity.activityTypeID === activityTypeID
    );

  const isActivityCreationNotAllowed = (activityTypeID: string): boolean =>
    getActivitiesCreationNotAllowed(planningActivityTypes, startDateValue).some(
      (activity) => activity.activityTypeID === activityTypeID
    );

  const handleCandidatesDisabledFilter = (option: {
    activityTypeID: string;
  }): boolean => {
    const candidateChanges = getValues("candidates");
    if (candidateChanges && candidateChanges.length <= 0) {
      return false;
    } else if (candidateChanges) {
      const foundPlanningAct = candidateChanges.find(
        (item) => item.activityTypeID === option.activityTypeID
      );
      if (foundPlanningAct) {
        return true;
      } else if (isActivityImmutable(option.activityTypeID)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const handleDelete = () => {
    const sortlocalPlanningActivityTypes = localPlanningActivityTypes.concat(
      currentDeletePendingActivityType
    );

    const newActivityArrayAfterDelete = removeInArray(
      selectedActivityTypes,
      currentDeletePendingActivityType
    );

    sortlocalPlanningActivityTypes.sort(function (a, b) {
      const textA = a.name.toUpperCase();
      const textB = b.name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    setLocalPlanningActivityTypes(sortlocalPlanningActivityTypes);

    setSelectedActivityTypes(newActivityArrayAfterDelete);
    setValue("activityTypes", newActivityArrayAfterDelete);
    setChecked(not(checked, currentDeletePendingActivityType));

    if (newActivityArrayAfterDelete.length <= 0) {
      setValue("resources", []);
      setValue("locationID", null);
    }

    setCurrentDeletePendingActivityType([]);
    setOpenDeleteConfirmDialog(false);
  };

  const formatLocation = (location: RelationAddress) =>
    `${location.street} ${location.number}, ${location.postalcode} ${location.city}`;

  const customList = (
    title: React.ReactNode,
    items: readonly PlanningActivityType[],
    isSelectedActivitiesList: Boolean
  ) => (
    <Card sx={{ width: 500 }}>
      <CardHeader sx={{ px: 2, py: 1 }} subheader={title} />
      {!isSelectedActivitiesList && (
        <CustomTextField
          variant="filled"
          controldisabled={!editAllowed}
          label={t("labels.searchActivityType")}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            filter(event.target.value.toLocaleLowerCase());
          }}
          value={searchText}
          type="search"
        />
      )}
      <Divider />
      {items && items.length > 0 && (
        <List
          sx={{
            width: 490,
            maxHeight: 190,
            bgcolor: "background.paper",
            overflow: "auto",
          }}
          dense
          component="div"
          role="list"
        >
          {items.map((value: any) => {
            const labelId = `transfer-list-all-item-${value.name}-label`;

            return (
              <ListItemButton
                disableRipple
                key={value.activityTypeID}
                disabled={
                  !isSelectedActivitiesList
                    ? handleDisabledFilter(value, selectedActivityTypes) ||
                      handleDisabledFilter(value, checked)
                    : false
                }
                onClick={
                  !isSelectedActivitiesList ? handleToggle(value) : () => {}
                }
              >
                <ListItemIcon>
                  {!isSelectedActivitiesList && (
                    <Checkbox
                      checked={checked.includes(value)}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{
                        "aria-labelledby": labelId,
                      }}
                      style={{ padding: 2 }}
                      size="small"
                      disabled={
                        handleDisabledFilter(value, selectedActivityTypes) ||
                        handleDisabledFilter(value, checked)
                      }
                    />
                  )}
                </ListItemIcon>
                <ListItemText id={labelId} primary={`${value.name}`} />
                {isSelectedActivitiesList && (
                  <ListItemSecondaryAction>
                    <Tooltip
                      title={
                        isPublicExam
                          ? t("feedback.immutableActivityHelpText")
                          : handleCandidatesDisabledFilter(value)
                          ? t("feedback.deleteActivityHelpText")
                          : isActivityImmutable(value.activityTypeID)
                          ? t("feedback.immutableActivityHelpText")
                          : t("labels.delete")
                      }
                    >
                      {/* span added so as to activate the tooltip for disabled button on hover */}
                      <span>
                        <IconButton
                          color="primary"
                          onClick={() => {
                            setCurrentDeletePendingActivityType([value]);
                            setOpenDeleteConfirmDialog(true);
                          }}
                          disabled={
                            isPublicExam ||
                            handleCandidatesDisabledFilter(value)
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </ListItemSecondaryAction>
                )}
              </ListItemButton>
            );
          })}
          <ListItem />
        </List>
      )}

      {openDeleteConfirmDialog && (
        <CustomDialog
          open={openDeleteConfirmDialog}
          leftButtonHeading={t("labels.cancel")}
          rightButtonHeading={t("labels.delete")}
          handleCancel={handleCancel}
          handleClosePressed={handleDelete}
          heading={t("common.deleteActivityConfirm")}
        />
      )}
    </Card>
  );

  return (
    <Paper>
      <Stack
        sx={{
          maxHeight: windowSize.height - 400,
          overflowY: "auto",
          overflowX: "hidden",
        }}
        direction={"row"}
        padding={2}
        spacing={2}
      >
        <Stack direction="column" spacing={2} padding={2}>
          <FormControl sx={{ width: "500px" }}>
            <CustomDateTimePickerController
              label={t("labels.startDate")}
              name="moment.startDate"
              control={control}
              controlDisabled={true}
            />
          </FormControl>
          <FormControl variant="filled">
            <Grid>
              <Grid item>
                {customList("", localPlanningActivityTypes, false)}
              </Grid>
            </Grid>
          </FormControl>
          <FormControl variant="filled" sx={{ marginLeft: 2 }}>
            <CustomTextField
              name={"locationID"}
              disabled
              label={t("labels.location")}
              value={formatLocation(locationDetails)}
            />
          </FormControl>
          {enableUseOpenExam && (
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              spacing={2}
              padding={2}
              width={"100%"}
            >
              <CustomCheckboxController
                label={t("labels.publicExam")}
                control={control}
                name="publicExam"
                controlDisabled
              />
              <CustomNumberController
                control={control}
                name={"publicExamSeats"}
                label={t("labels.publicExamSeats")}
                disabled={true}
                sx={{ width: "140px" }}
              />
            </Stack>
          )}
          <FormControl variant="filled">
            <ExamResourcesAutoComplete
              name={"resources"}
              control={control}
              controlDisabled={true}
              errors={form.formState.errors["resources"]}
              resources={allResources}
              defaultValue={allResources.filter((res) =>
                getValues("resources")
                  .map((x) => x.resourceID)
                  .includes(res.resourceID)
              )}
              label="examiner"
              inputSelectedActivityTypes={
                (selectedActivityTypes && selectedActivityTypes) || []
              }
            />
          </FormControl>
          <FormControl variant="outlined" sx={{ width: "500px" }}>
            <CustomTextfieldController
              control={control}
              controlDisabled={!editAllowed}
              name="moment.notes"
              id="outlined-adornment-note"
              multiline
              label={t("labels.note")}
              rows={8}
              fullWidth
            />
          </FormControl>
        </Stack>
        {/* Activity transfer list button */}
        <Stack direction="column" paddingTop={48} marginLeft={4}>
          <Tooltip
            title={
              leftChecked.length === 0
                ? t("labels.noActivitySelected")
                : t("common.addActivity")
            }
          >
            {/* span added so as to activate the tooltip for disabled button on hover */}
            <span>
              <Button
                sx={{ my: 0.5, height: 30 }}
                variant="contained"
                size="small"
                onClick={async () => await handleCheckedRight()}
                disabled={
                  leftChecked.length === 0 ||
                  selectedActivityTypes.some((activity) =>
                    isActivityImmutable(activity.activityTypeID)
                  )
                }
              >
                &gt;
              </Button>
            </span>
          </Tooltip>
        </Stack>

        <Stack direction="column" padding={2}>
          <Grid>
            <Grid item>
              {customList(
                t("common.mappedActivityList"),
                selectedActivityTypes,
                true
              )}
            </Grid>
          </Grid>
          {form.formState &&
            form.formState.errors &&
            form.formState.errors["activityTypeIDs"] && (
              <Stack>
                <CustomTypographyComponent color={theme.appColors.error}>
                  {form.formState.errors["activityTypeIDs"].message}
                </CustomTypographyComponent>
              </Stack>
            )}
        </Stack>
      </Stack>
    </Paper>
  );
};

export default ExamDetailsForm;
