import { CustomModal } from "@cbex/ui/modal";
import { Stack } from "@mui/material";
import { useTranslations } from "next-intl";
import React from "react";
import ImportCandidatesDetails from "./ImportCandidatesDetails";
import {
  CandidatesByActivityTypeIDsAndDateRow,
  GetCbexCandidatesByActivityTypeIDsAndDateDocument,
  PlanningActivityType,
} from "@cbex/data-access";
import { useQuery } from "@apollo/client";
import { CustomDateRangePickerComponent } from "@cbex/ui/input";
import dayjs from "dayjs";
import { ActivityFilterValues } from "./ActivityOverviewComposite";
import ActivityTypeAutoCompleteComponent from "./Refactor/ActivityTypeAutoCompleteComponent";

interface ImportCandidateModalProps {
  modalOpen: boolean;
  handleClose: () => void;
  handleCloseWithData: (data: CandidatesByActivityTypeIDsAndDateRow[]) => void;
  activityTypeIDs: Array<string>;
}

type ExtendedFilter = ActivityFilterValues & {
  activityTypeIDs: Array<string>;
};

export const ImportCandidateModal = ({
  handleClose,
  modalOpen,
  handleCloseWithData,
  activityTypeIDs,
}: ImportCandidateModalProps) => {
  const [modalState] = React.useState(modalOpen);
  const [localActivityTypeIds] = React.useState(activityTypeIDs);
  const t = useTranslations();
  const [filterValues, setFilterValues] = React.useState<ExtendedFilter>({
    dateFrom: dayjs().subtract(3, "month"),
    dateTill: dayjs().add(3, "month"),
    activityTypeIDs:
      localActivityTypeIds &&
      localActivityTypeIds.length > 0 &&
      //@ts-ignore
      localActivityTypeIds.map((items) => items.activityTypeID),
  });
  const [candidateData, setCandidateData] =
    React.useState<CandidatesByActivityTypeIDsAndDateRow[]>();

  const { data } = useQuery(GetCbexCandidatesByActivityTypeIDsAndDateDocument, {
    fetchPolicy: "network-only",
    variables: {
      input: {
        activityTypeIds: filterValues.activityTypeIDs,
        dateFrom: filterValues.dateFrom,
        dateTill: filterValues.dateTill,
      },
    },
  });

  React.useEffect(() => {
    if (
      data &&
      data.CBEXCandidatesByActivityTypeIDsAndDate &&
      data.CBEXCandidatesByActivityTypeIDsAndDate.length > 0
    ) {
      setCandidateData(data.CBEXCandidatesByActivityTypeIDsAndDate);
    }
  }, [data]);

  const handleDateRange = (input: any[]) => {
    setFilterValues({
      ...filterValues,
      dateFrom: input[0],
      dateTill: input[1],
    });
  };

  const handleActivityUpdate = (input: PlanningActivityType[]) => {
    setFilterValues({
      ...filterValues,
      activityTypeIDs: input && input.map((items) => items.activityTypeID),
    });
  };

  return (
    <Stack id="ImportCandidateModal">
      <CustomModal
        modalTitle={t("common.importCandidates")}
        openModal={modalState}
        closeHandler={handleClose}
      >
        <>
          <Stack direction={"row"} spacing={3}>
            <CustomDateRangePickerComponent
              controldisabled={false}
              width={300}
              label={t("labels.dateRangeFilter")}
              value={[filterValues.dateFrom, filterValues.dateTill]}
              onChange={(newValue) => {
                //@ts-ignore
                handleDateRange(newValue);
              }}
            />

            <ActivityTypeAutoCompleteComponent
              defaultValue={filterValues.activityTypeIDs}
              onActivityChange={handleActivityUpdate}
            />
          </Stack>
          <ImportCandidatesDetails
            onImportClick={handleCloseWithData}
            data={candidateData}
          />
        </>
      </CustomModal>
    </Stack>
  );
};

export default ImportCandidateModal;
