import React from "react";
import { Button, Stack, Box, Fade } from "@mui/material";
import { useTranslations } from "next-intl";
import { CustomTypographyComponent } from "@cbex/ui/text";
import { ForgotPasswordForm, ForgotPasswordFormState } from "../index";
import { useTheme } from "@cbex/utils/theme";

import axios from "axios";
import { MdArrowBack } from "react-icons/md";
import { useRouter } from "next/navigation";

export interface ForgotPasswordFormCompositeProps {
  onForgotPasswordDetails: (val: ForgotPasswordFormState) => void;
  forgotRef: React.RefObject<any>;
  failedReset: boolean;
  handleForgotClicked: () => void;
}

const ForgotPasswordFormComposite = ({
  onForgotPasswordDetails,
  forgotRef,
  failedReset,
  handleForgotClicked,
}: ForgotPasswordFormCompositeProps) => {
  const t = useTranslations("login");
  const theme = useTheme();
  return (
    <Stack width="100%" gap={5} alignItems="center">
      <CustomTypographyComponent sx={{ textAlign: "center" }}>
        {t("fillDetailsForgotPassword")}
      </CustomTypographyComponent>
      {failedReset && (
        <CustomTypographyComponent
          variant="body1"
          color={theme.appColors.error}
          sx={{ textAlign: "center" }}
        >
          {t("failedPaswordReset")}
        </CustomTypographyComponent>
      )}
      <Stack justifyContent={"center"} alignItems="center" width="100%">
        <ForgotPasswordForm
          onForgotPasswordDetails={onForgotPasswordDetails}
          ref={forgotRef}
        />
      </Stack>

      <Stack>
        <Stack alignItems={"center"}>
          <Button
            color="primary"
            variant="contained"
            onClick={handleForgotClicked}
          >
            {t("resetPassword")}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

const PasswordSentComposite = ({
  setIsSubmitted,
  setFailedReset,
}: {
  setIsSubmitted: (val: boolean) => void;
  setFailedReset: (val: boolean) => void;
}) => {
  const t = useTranslations("login");
  const router = useRouter();

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      height="100%"
      gap={5}
      p={5}
    >
      <CustomTypographyComponent variant="body2" sx={{ textAlign: "justify" }}>
        {t("passwordResetEmailSent")}
      </CustomTypographyComponent>
      <CustomTypographyComponent
        variant="body1"
        sx={{ textAlign: "justify", fontWeight: "bold" }}
      >
        {t("passwordResetNoEmailQuestion")}
      </CustomTypographyComponent>
      <CustomTypographyComponent variant="body2" sx={{ textAlign: "justify" }}>
        {t("passwordResetNoEmail")}
      </CustomTypographyComponent>
      <Button
        variant="contained"
        color="primary"
        sx={{ mt: 2 }}
        onClick={() => {
          setIsSubmitted(false);
          setFailedReset(false);
          router.replace("/login");
        }}
        startIcon={<MdArrowBack />}
      >
        {t("back2login")}
      </Button>
    </Stack>
  );
};

export const ForgotPasswordComposite = () => {
  const t = useTranslations("login");
  const forgotRef = React.useRef();
  const theme = useTheme();
  const [failedReset, setFailedReset] = React.useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = React.useState<boolean>(false);

  const handleForgotDetails = (val: ForgotPasswordFormState) => {
    if (val) {
      axios
        .post(
          "auth/forgotPassword",
          { EmailAddress: val.userName },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            withCredentials: true,
          }
        )
        .then((res: any) => {
          setIsSubmitted(true);
          if (res && res.data && res.data.Status === 200) {
            setFailedReset(false);
          } else {
            setFailedReset(true);
          }
        })
        .catch((FEerror: any) => console.log("FE error", FEerror));
    }
  };

  const handleForgotClicked = () => {
    if (forgotRef && forgotRef.current) {
      //@ts-ignore
      forgotRef.current.onSubmit();
    }
  };

  return (
    <Stack
      alignItems="center"
      minHeight="500px"
      width="500px"
      id="ForgotPasswordComposite"
    >
      <Stack mt={3} height={"20%"} mb={6}>
        <Box
          component="img"
          sx={{
            height: 80,
          }}
          alt="Cbex logo"
          src="https://storage.googleapis.com/cbex/cbex_groen_ondertitel_RGB.png"
        />
      </Stack>
      <Stack
        spacing={2}
        justifyContent={"center"}
        alignItems="center"
        width="100%"
      >
        <CustomTypographyComponent variant="h5">
          {t("forgotPassword")}
        </CustomTypographyComponent>
        <Stack
          position="relative"
          width="100%"
          sx={{ mt: 5, minHeight: "300px" }}
        >
          <Fade in={!isSubmitted || failedReset} timeout={800} unmountOnExit>
            <Stack
              sx={{
                width: "100%",
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0,
              }}
            >
              <ForgotPasswordFormComposite
                onForgotPasswordDetails={handleForgotDetails}
                forgotRef={forgotRef}
                failedReset={failedReset}
                handleForgotClicked={handleForgotClicked}
              />
            </Stack>
          </Fade>
          <Fade in={isSubmitted && !failedReset} timeout={800} unmountOnExit>
            <Stack
              sx={{
                width: "100%",
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0,
                pt: 2, // Add padding top to create space from the header
              }}
            >
              <PasswordSentComposite
                setIsSubmitted={setIsSubmitted}
                setFailedReset={setFailedReset}
              />
            </Stack>
          </Fade>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ForgotPasswordComposite;
