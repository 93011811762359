import {
  CbexMomentExam,
  CbexPortalCandidatesByMomentIdRow,
  PlanningActivityType,
} from "@cbex/data-access";
import dayjs from "dayjs";

export const getExamEditState = (input: CbexMomentExam): boolean => {
  const settings = input.activities[0].activityType.settings;
  if (settings) {
    if (settings.candidateAllowedMutationMinutes) {
      const today = dayjs();
      const hourDiff = dayjs(input.moment.startDate).diff(today, "minutes");

      if (hourDiff >= settings.candidateAllowedMutationMinutes) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  } else {
    return true;
  }
};

export const getCandidatesImmutable = (
  allActivities: PlanningActivityType[],
  allCandidates: CbexPortalCandidatesByMomentIdRow[],
  momentStartDate: string
) =>
  allCandidates.filter((candidate) =>
    getActivitiesImmutable(allActivities, momentStartDate, true).some(
      (activity) => activity.activityTypeID === candidate.activityTypeID
    )
  );

export const getActivitiesImmutable = (
  allActivities: PlanningActivityType[],
  momentStartDate: string,
  forCandidates?: boolean
) =>
  allActivities.filter(
    (activity) =>
      dayjs(momentStartDate).diff(dayjs(), "minutes") <
      (forCandidates
        ? activity?.settings?.candidateMutationAllowedMinutes
        : activity?.settings?.activityMutationAllowedMinutes)
  );

export const getActivitiesCreationNotAllowed = (
  allActivities: PlanningActivityType[],
  momentStartDate: string
) =>
  allActivities.filter(
    (activity) =>
      dayjs(momentStartDate).diff(dayjs(), "minutes") <
      activity?.settings?.allowNewExamMinutes
  );
