import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useWindowDimensions } from "@cbex/utils/hooks";
import { set } from "lodash";
import nl from "date-fns/locale/nl";
import { useTheme } from "@cbex/utils/theme";
import dayjs from "dayjs";
import { PublicExamOverviewRow } from "@cbex/data-access";
import { useTranslations } from "next-intl";
interface DisplayTableProps {
  columns: any;
  rows: PublicExamOverviewRow[];
  handeRowClicked?: any;
  activeItem?: any;
}

export const DisplayTable = (props: DisplayTableProps) => {
  const { columns, rows, handeRowClicked, activeItem } = props;

  const { height } = useWindowDimensions();
  const theme = useTheme();
  const [activeID, setActiveID] = React.useState(-1);
  const [rowData, setRowData] = React.useState<PublicExamOverviewRow>();
  const t = useTranslations();
  const handleClick = (event: React.MouseEvent<unknown>, name: any) => {
    setActiveID(name.ID);
    setRowData(name);
    handeRowClicked(name);
  };

  const getSeatsRemaining = (row: PublicExamOverviewRow) => {
    const totalSeatsStillAvailable =
      row.moment.properties.examCapacity - row.totalCandidates;
    const totalPublicSeatsAvailable =
      row.moment.properties.publicExamSeats - row.publicCandidates;

    if (
      totalPublicSeatsAvailable > 0 &&
      totalSeatsStillAvailable >= totalPublicSeatsAvailable
    ) {
      return totalPublicSeatsAvailable;
    } else if (
      totalPublicSeatsAvailable > 0 &&
      totalSeatsStillAvailable < totalPublicSeatsAvailable
    ) {
      return totalSeatsStillAvailable;
    } else {
      return -1;
    }
  };

  React.useEffect(() => {
    if (activeItem) {
      setActiveID(activeItem.momentID);
    }
  }, [activeItem]);

  return (
    <TableContainer
      sx={{
        //@ts-ignore
        minHeight: 350,
        //@ts-ignore
        maxHeight: height - 580,
        overflow: "auto",
      }}
      component={Paper}
    >
      <Table
        sx={{
          maxWidth: 650,

          [`& .${tableCellClasses.root}`]: {
            borderBottom: "none",
          },
        }}
        size="small"
        aria-label="a dense table"
        stickyHeader
      >
        <TableHead>
          <TableRow>
            {columns.map((col: any, index: number) => (
              <TableCell key={col.ID + index.toString()}>{col.name}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row: PublicExamOverviewRow, index: number) => (
            <TableRow
              key={row.momentID + index.toString()}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              selected={row.momentID === activeID.toString()}
              style={{
                backgroundColor:
                  activeID.toString() === row.momentID
                    ? theme.palette.success.light
                    : undefined,
                cursor: getSeatsRemaining(row) <= 0 ? "not-allowed" : "pointer",
              }}
              onClick={(event) =>
                getSeatsRemaining(row) <= 0 ? null : handleClick(event, row)
              }
            >
              <TableCell component="th" scope="row">
                {dayjs(row.moment.startDate).format("DD MMMM ")}
              </TableCell>
              <TableCell>
                {dayjs(row.moment.startDate).format("HH:mm")}
              </TableCell>
              <TableCell>{row.moment.location.city}</TableCell>
              <TableCell>
                {getSeatsRemaining(row) > 0
                  ? getSeatsRemaining(row)
                  : t("labels.fullCapacity")}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DisplayTable;
