import React from "react";
import { Stack } from "@mui/system";
import { useTranslations } from "next-intl";
import Add from "@mui/icons-material/Add";
import AddBox from "@mui/icons-material/AddBox";
import {
  CustomDateRangePickerComponent,
  CustomTextField,
} from "@cbex/ui/input";
import dayjs from "dayjs";
import { Button, IconButton, InputAdornment } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@cbex/utils/theme";
import SearchIcon from "@mui/icons-material/Search";
import { AppContext } from "@cbex/utils/context";
import CloseIcon from "@mui/icons-material/Close";

interface ActivityFilterValues {
  dateFrom: any;
  dateTill: any;
}

interface ActivityFilterComponentProps {
  inputValues?: ActivityFilterValues;
  onDataChanged: (inputParams: ActivityFilterValues) => void;
  isActivityOverview: boolean;
  isNotCandidateHistory?: boolean;
  onNewPressed: () => void;
  onPublicExamPressed?: () => void;
  onSearchChanged?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const ActivityFilterComponent = (
  props: ActivityFilterComponentProps
) => {
  const {
    inputValues,
    isActivityOverview,
    onNewPressed,
    onDataChanged,
    isNotCandidateHistory,
    onPublicExamPressed,
    onSearchChanged,
  } = props;
  const t = useTranslations();
  const theme = useTheme();
  //@ts-ignore
  const { localAppState } = React.useContext(AppContext);
  const { enableUseOpenExam, enableNewExam } =
    localAppState?.userData?.organization?.properties;
  const [searchText, setSearchText] = React.useState("");
  const smallDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const [filterValues, setFilterValues] = React.useState<ActivityFilterValues>({
    //@ts-ignore
    dateFrom: dayjs() || inputValues.dateFrom,
    //@ts-ignore
    dateTill: dayjs().add(3, "month") || inputValues.dateTill,
  });

  React.useEffect(() => {
    if (inputValues) {
      setFilterValues(inputValues);
    }
  }, [inputValues]);

  const handleDateRange = (input: any[]) => {
    setFilterValues({
      ...filterValues,
      dateFrom: input[0],
      dateTill: input[1],
    });
    onDataChanged({
      ...filterValues,
      dateFrom: input[0],
      dateTill: input[1],
    });
  };

  return (
    <Stack id="ActivityFilterComponent">
      <Stack spacing={2} direction={"row"} width="100%" alignItems={"center"}>
        <Stack>
          <CustomDateRangePickerComponent
            controldisabled={false}
            width={300}
            label={t("labels.dateRangeFilter")}
            value={[filterValues.dateFrom, filterValues.dateTill]}
            minDate={
              isActivityOverview ? dayjs().subtract(3, "month") : undefined
            }
            maxDate={isActivityOverview ? undefined : dayjs()}
            onChange={(newValue) => {
              //@ts-ignore
              handleDateRange(newValue);
            }}
          />
        </Stack>

        <Stack
          width={{ md: "100%", sm: "40%" }}
          alignSelf={"flex-end"}
          height={"100%"}
          alignItems={"center"}
          flexDirection={"row"}
        >
          {isNotCandidateHistory && (
            <Stack>
              <CustomTextField
                placeholder={t("labels.searchText")}
                variant="filled"
                hiddenLabel
                sx={{ width: 300 }}
                useFullWidth={false}
                inputProps={{ style: { height: "31px" } }}
                value={searchText}
                onChange={(event) => {
                  setSearchText(event.target.value);
                  //@ts-ignore
                  onSearchChanged(event);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment sx={{ height: 48 }} position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {searchText.length >= 1 && (
                        <IconButton
                          onClick={() => {
                            setSearchText("");
                            onSearchChanged(null);
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
          )}
          {isActivityOverview && enableNewExam && (
            <Stack width={{ md: "80%", sm: "40%" }} direction={"row-reverse"}>
              {!smallDevice && (
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={onNewPressed}
                  startIcon={<Add />}
                >
                  {t("common.newExam")}
                </Button>
              )}
              {smallDevice && (
                <IconButton disableRipple onClick={onNewPressed}>
                  <AddBox
                    style={{
                      fontSize: 30,
                    }}
                    sx={{
                      color: theme.palette.primary.main,
                    }}
                  />
                </IconButton>
              )}
            </Stack>
          )}

          {isActivityOverview && enableUseOpenExam && (
            <Stack width={{ md: "60%", sm: "30%" }} direction={"row-reverse"}>
              {!smallDevice && (
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={onPublicExamPressed}
                  startIcon={<Add />}
                >
                  {t("common.publicExam")}
                </Button>
              )}
              {smallDevice && (
                <IconButton disableRipple onClick={onPublicExamPressed}>
                  <AddBox
                    style={{
                      fontSize: 30,
                    }}
                    sx={{
                      color: theme.palette.primary.main,
                    }}
                  />
                </IconButton>
              )}
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ActivityFilterComponent;
