import { Controller } from "react-hook-form";
import React from "react";
import { CustomNumberComponent } from "@cbex/ui/input";
import { TextFieldProps } from "@mui/material/TextField";
import { SxProps } from "@mui/material/styles";

type CustomNumberControllerProps = TextFieldProps & {
  name: string;
  control: any;
  label?: string;
  multiLine?: boolean;
  rows?: number;
  setValue?: any;
  fullWidth?: boolean;
  suffix?: string;
  controlDisabled?: boolean;
  allowNegative?: boolean;
  sx?: SxProps;
};

export const CustomNumberController = ({
  name,
  control,
  fullWidth,
  multiLine,
  controlDisabled,
  placeholder,
  rows,
  suffix,
  label,
  allowNegative = true,
  sx,
  ...props
}: CustomNumberControllerProps) => (
  <Controller
    name={name}
    control={control}
    render={({
      field: { onChange, value },
      fieldState: { error },
      formState,
    }) => (
      <CustomNumberComponent
        helperText={error ? error.message : null}
        error={!!error}
        onValue={onChange}
        rows={rows}
        disabled={controlDisabled}
        multiline={multiLine}
        value={value}
        fullWidth={fullWidth}
        label={label}
        variant="filled"
        placeholder={placeholder || undefined}
        suffix={suffix}
        sx={sx}
        {...props}
        allowNegative={allowNegative}
      />
    )}
  />
);
