import { useQuery } from "@apollo/client";
import { InputAdornment, Stack } from "@mui/material";
import React from "react";
import {
  PublicExamOverviewDocument,
  PublicExamOverviewRow,
} from "@cbex/data-access";
import { ActivityFilterValues } from "./ActivityOverviewComposite";
import dayjs from "dayjs";
import { IoMdSearch } from "react-icons/io";
import DisplayTable from "./ui/PublicExamDisplayTable";
import { useTranslations } from "next-intl";
import {
  CustomDateRangePickerComponent,
  CustomTextField,
} from "@cbex/ui/input";

const OrderColumns = [
  { id: "1", name: "Datum" },
  { id: "2", name: "Tijd" },
  { id: "3", name: "Plaats" },
  { id: "4", name: "Plekken vrij" },
];

interface PublicExamsOverviewProps {
  onRowClicked: (exam: PublicExamOverviewRow) => void;
}
export const PublicExamsOverview = ({
  onRowClicked,
}: PublicExamsOverviewProps) => {
  const [filterValues, setFilterValues] = React.useState<ActivityFilterValues>({
    dateFrom: dayjs().add(1, "day"),
    dateTill: dayjs().add(6, "month"),
  });
  const t = useTranslations();
  const { data, loading } = useQuery(PublicExamOverviewDocument, {
    variables: {
      dateFrom: filterValues.dateFrom,
      dateTill: filterValues.dateTill,
    },
    fetchPolicy: "network-only",
  });

  const [masterExamDataList, setMasterExamDataList] = React.useState<
    PublicExamOverviewRow[]
  >([]);
  const [examDataList, setExamDataList] = React.useState<
    PublicExamOverviewRow[]
  >([]);
  const [citySearch, setCitySearch] = React.useState("");
  const [currentRow, setCurrentRow] = React.useState<PublicExamOverviewRow>();

  React.useEffect(() => {
    if (!loading && data?.PublicExamOverview?.length > 0) {
      setExamDataList(data.PublicExamOverview);
      setMasterExamDataList(data.PublicExamOverview);
    } else {
      setExamDataList([]);
      setMasterExamDataList([]);
    }
  }, [data, loading]);

  const handleDateRange = (input: any[]) => {
    setFilterValues({
      ...filterValues,
      dateFrom: input[0],
      dateTill: input[1],
    });
  };

  const handleTextChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (masterExamDataList?.length > 0) {
      setCitySearch(event.target.value);
      const filteredData = masterExamDataList.filter((item) =>
        item.moment.location.city
          .toLowerCase()
          .includes(event.target.value.toLowerCase())
      );
      setExamDataList(filteredData);
    }
  };

  return (
    <Stack spacing={2} id="PublicExamsOverview">
      <Stack
        spacing={2}
        direction={"row"}
        p={2}
        width="100%"
        alignItems={"center"}
      >
        <CustomDateRangePickerComponent
          controldisabled={false}
          width={300}
          label={t("labels.dateRangeFilter")}
          value={[filterValues.dateFrom, filterValues.dateTill]}
          minDate={dayjs().subtract(3, "month")}
          maxDate={undefined}
          onChange={(newValue) => {
            //@ts-ignore
            handleDateRange(newValue);
          }}
        />
        <CustomTextField
          value={citySearch}
          sx={{ width: 300 }}
          onChange={(event) => handleTextChange(event)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IoMdSearch />
              </InputAdornment>
            ),
          }}
          label={t("labels.city")}
        />
      </Stack>
      {examDataList.length === 0 ? (
        <Stack>{t("common.noData")}</Stack>
      ) : (
        <DisplayTable
          columns={OrderColumns}
          rows={examDataList}
          handeRowClicked={(item) => {
            setCurrentRow(item);

            onRowClicked(item);
          }}
          activeItem={currentRow}
        />
      )}
    </Stack>
  );
};
