import { FormControl, FormHelperText, Stack } from "@mui/material";
import { useTranslations } from "next-intl";
import React from "react";
import { SnackBar } from "@cbex/ui/snack-bar";
import { useTheme } from "@cbex/utils/theme";
import { CustomTextField } from "@cbex/ui/input";
import { isEmail } from "../utils";

interface ForgotPasswordFormProps {
  onForgotPasswordDetails: (val: ForgotPasswordFormState) => void;
  data?: any;
}

export interface ForgotPasswordFormState {
  userName: string;
}

interface ForgotPasswordFormError {
  userName: string;
}

export const ForgotPasswordForm = React.forwardRef(
  (props: ForgotPasswordFormProps, ref) => {
    const t = useTranslations();
    const { onForgotPasswordDetails, data } = props;
    const theme = useTheme();
    const [values, setValues] = React.useState<ForgotPasswordFormState>({
      userName: "",
    });
    const [formIsSubmitted, setFormIsSubmitted] = React.useState(false);
    const [snackBar, setSnackBar] = React.useState(false);
    const [snackBarMessage, setSnackBarMessage] = React.useState("");
    const [severity, setSeverity] = React.useState("");
    const [formErrors, setFormErrors] = React.useState<ForgotPasswordFormError>(
      {
        userName: t("feedback.invalidEmail"),
      }
    );

    const handleChange =
      (prop: keyof ForgotPasswordFormState) =>
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const localValue = event.target.value.trim();
        if (prop === "userName" || prop === "password") {
          if (prop === "userName") {
            if (prop === "userName" && localValue === "") {
              formErrors.userName = t("feedback.missingValues");
            } else {
              if (!isEmail(localValue)) {
                formErrors.userName = t("feedback.invalidEmail");
              } else {
                formErrors.userName = "";
              }
            }
          }
        }
        setFormErrors({ ...formErrors });
        setValues({ ...values, [prop]: localValue });
      };

    React.useImperativeHandle(ref, () => ({
      onSubmit() {
        setFormIsSubmitted(true);
        if (Object.values(formErrors).some((item) => item.length > 0)) {
          setSnackBar(true);
          setSeverity("error");
          setSnackBarMessage(t("feedback.missingValues"));
        } else {
          setFormErrors({ userName: "" });
          onForgotPasswordDetails(values);
        }
      },
    }));

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
      if (reason === "clickaway") {
        return;
      }

      setSnackBar(false);
    };
    return (
      <Stack width="100%" id="LoginForm" spacing={2} padding={1}>
        <Stack
          width="100%"
          alignItems="center"
          spacing={2}
          padding={1}
          direction={"column"}
        >
          <FormControl sx={{ width: "70%" }} variant="outlined">
            <CustomTextField
              id="outlined-userName"
              label={t("labels.email")}
              controldisabled={false}
              required
              value={values.userName}
              onChange={handleChange("userName")}
            />
            {formIsSubmitted && (
              <FormHelperText
                sx={{
                  color: formErrors.userName ? theme.appColors.error : "",
                }}
                id="filled-userName-helper-text"
              >
                {formErrors.userName ? formErrors.userName : ""}
              </FormHelperText>
            )}
          </FormControl>
        </Stack>
        <SnackBar
          openState={snackBar}
          //@ts-ignore
          severity={severity}
          message={snackBarMessage}
          //@ts-ignore
          onClose={handleClose}
        ></SnackBar>
      </Stack>
    );
  }
);

ForgotPasswordForm.displayName = "ForgotPasswordForm";

export default ForgotPasswordForm;
