import React from "react";
import { Chip, Stack, SxProps, Theme } from "@mui/material";
import { CustomTextField } from "@cbex/ui/input";
import { PlanningActivityType } from "@cbex/data-access";
import { CustomAutoCompleteController } from "@cbex/form/autocomplete";
import { CustomTypographyComponent } from "@cbex/ui/text";

type InputActivityType = PlanningActivityType & {
  disabled?: boolean;
};

interface ActivityTypeAutoCompleteProps {
  inputPlanningActivityTypes: InputActivityType[];
  defaultValue?: any;
  width?: number | string;
  name: string;
  controlDisabled?: boolean;
  control?: any;
  sx?: SxProps<Theme>;
  disabledPlanningActivities?: PlanningActivityType[];
}

export const SingleActivityTypeAutoComplete = ({
  inputPlanningActivityTypes,
  control,
  defaultValue,
  width,
  name,
  controlDisabled,
  disabledPlanningActivities,
  sx,
}: ActivityTypeAutoCompleteProps) => {
  const [value, setValue] = React.useState(null);

  const [options, setOptions] = React.useState<PlanningActivityType[]>([]);

  React.useEffect(() => {
    if (inputPlanningActivityTypes && inputPlanningActivityTypes.length > 0) {
      setOptions(inputPlanningActivityTypes);
    } else {
      setOptions([]);
    }
  }, [inputPlanningActivityTypes]);

  React.useEffect(() => {
    if (
      defaultValue &&
      inputPlanningActivityTypes &&
      inputPlanningActivityTypes.length > 0
    ) {
      const foundAct = inputPlanningActivityTypes.find(
        (act: PlanningActivityType) => act.activityTypeID === defaultValue
      );
      setValue(foundAct);
    }
  }, [defaultValue, inputPlanningActivityTypes]);

  const handleActivityChange = (inputValue: any) => {
    setValue(inputValue);
  };

  return (
    <Stack id="ActivityTypeAutoComplete">
      {inputPlanningActivityTypes && inputPlanningActivityTypes.length > 0 && (
        <CustomAutoCompleteController
          id="async-activitiesAutoComplete"
          sx={[
            { width: width ? width : 500 },
            ...(Array.isArray(sx) ? sx : [sx]),
          ]}
          control={control}
          customID="activityTypeID"
          disabled={controlDisabled}
          fullObject={true}
          disableClearable={true}
          onCustomChange={(val) => handleActivityChange(val)}
          name={name}
          getOptionLabel={(option) => option.name}
          getOptionDisabled={(option) =>
            disabledPlanningActivities && disabledPlanningActivities.length > 0
              ? disabledPlanningActivities.some(
                  (activity) =>
                    activity.activityTypeID === option.activityTypeID
                )
              : false
          }
          isOptionEqualToValue={(option, val) =>
            !option.disabled && option.activityTypeID === val.activityTypeID
          }
          renderOption={(props, option) => {
            if (option.disabled) {
              return (
                <li
                  {...props}
                  style={{ pointerEvents: "none", opacity: 0.5 }}
                  key={option.activityTypeID}
                >
                  <Stack width={"100%"}>
                    <CustomTypographyComponent>
                      {option.name}
                    </CustomTypographyComponent>
                  </Stack>
                </li>
              );
            }
            return (
              <li {...props} key={option.activityTypeID}>
                <Stack width={"100%"}>
                  <CustomTypographyComponent>
                    {option.name}
                  </CustomTypographyComponent>
                </Stack>
              </li>
            );
          }}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                key={option.activityTypeID}
                label={option.name}
                disabled={option.disabled}
              />
            ))
          }
          value={value || null}
          options={options}
          defaultValue={value || null}
          renderInput={(params) => (
            <CustomTextField
              {...params}
              controldisabled={false}
              variant="filled"
            />
          )}
        />
      )}
    </Stack>
  );
};

export default SingleActivityTypeAutoComplete;
