import React from "react";
import { ActivityFilterComponent } from "./ActivityFilterComponent";
import { ActivityCard } from "./ActivityCard";
import { useWindowDimensions } from "@cbex/utils/hooks";
import dayjs from "dayjs";
import * as yup from "yup";
import _ from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import SaveIcon from "@mui/icons-material/Save";
import {
  GetExamOverviewWithFiltersDocument,
  CbexPortalExamOverviewWithFilterOptionsRow,
  AddExamDocument,
  ExamByMomentIdDocument,
  RelationAddress,
  LinkOrganizationToPublicMomentDocument,
  PublicExamOverviewRow,
  PlanningActivityType,
  GetActivityTypeDocument,
} from "@cbex/data-access";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";
import { CustomDialog, CustomModal } from "@cbex/ui/modal";
import { IconButton, Button, Stack, AlertColor } from "@mui/material";
import { useTranslations } from "next-intl";
import { getNewExamDetailsDefaultValues } from "../utils/ExamDetailsDefaultValues";
import NewExamForm from "../forms/NewExamForm";
import { SnackBar } from "@cbex/ui/snack-bar";
import CreateRelationAddressForm from "../forms/OrganizationAddressForm";
import ExamDetailsComposite from "./ExamDetailsComposite";
import { ExamDetailsProp } from "../forms/ExamDetailsForm";
import { PublicExamsOverview } from "./PublicExamsOverview";
import { AppContext } from "@cbex/utils/context";
import {
  getActivitiesCreationNotAllowed,
  getActivitiesImmutable,
} from "../utils/getExamEditState";

export interface ActivityFilterValues {
  dateFrom: any;
  dateTill: any;
}

export const ActivityOverviewComposite = () => {
  const [examDataList, setExamDataList] = React.useState<
    CbexPortalExamOverviewWithFilterOptionsRow[]
  >([]);

  const windowSize = useWindowDimensions();
  const t = useTranslations();
  //@ts-ignore
  const { localAppState } = React.useContext(AppContext);
  const [activityConfig, setactivityConfig] = React.useState();
  const [openAddressModal, setOpenAddressModal] =
    React.useState<boolean>(false);
  const [selectedMomentExam, setSelectedMomentExam] =
    React.useState<ExamDetailsProp>();
  const [selectedMomentID, setSelectedMomentExamID] = React.useState("");
  const [editTitle, setEditTitle] = React.useState("");
  const [createdNewAddress, setCreatedNewAddress] = React.useState(false);
  const addOrganisationAddressRef = React.useRef();
  const [showPublicExamModal, setShowPublicExamModal] = React.useState(false);
  const [publicExam, setPublicExam] = React.useState<PublicExamOverviewRow>();
  const [openExamModal, setOpenExamModal] = React.useState<boolean>(false);
  const [openUnsavedChangesDialog, setOpenUnsavedChangesDialog] =
    React.useState<boolean>(false);
  const [relationAddress, setRelationAddress] =
    React.useState<RelationAddress>(null);
  const [planningActivityTypes, setPlanningActivityTypes] = React.useState<
    PlanningActivityType[]
  >([]);
  const [snackBar, setSnackBar] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState<AlertColor>("success");

  const [filterValues, setFilterValues] = React.useState<ActivityFilterValues>({
    dateFrom: dayjs().subtract(3, "month"),
    dateTill: dayjs().add(3, "month"),
  });

  const examSchema = yup.object().shape({
    moment: yup.object().shape({
      startDate: yup.date().required(t("labels.required")),
    }),
    locationID: yup.string().required(t("labels.required")),
    activityTypes: yup
      .array()
      .test("activityTypes", t("labels.required"), (val, context) => {
        const { activityTypes } = context.parent;
        const startDate = context.parent.moment.startDate;
        if (activityTypes && activityTypes.length > 0) {
          const isImmutableActivity = [];
          const isNotAllowedCreationActivity = [];
          const immutableActivities = getActivitiesImmutable(
            planningActivityTypes,
            startDate,
            false
          );
          const notAllowedCreationActivities = getActivitiesCreationNotAllowed(
            planningActivityTypes,
            startDate
          );
          activityTypes.forEach((selectedActivity) => {
            isImmutableActivity.push(
              immutableActivities.some(
                (activity) =>
                  activity.activityTypeID === selectedActivity.activityTypeID
              )
            );
          });
          activityTypes.forEach((selectedActivity) => {
            isNotAllowedCreationActivity.push(
              notAllowedCreationActivities.some(
                (activity) =>
                  activity.activityTypeID === selectedActivity.activityTypeID
              )
            );
          });
          if (
            isImmutableActivity.includes(true) ||
            isNotAllowedCreationActivity.includes(true)
          ) {
            return context.createError({
              path: `activityTypeIDs`,
              message: t("feedback.immutableActivitySelectedForExam"),
            });
          } else return context.resolve(true);
        } else if (activityTypes && activityTypes.length <= 0) {
          return context.createError({
            path: `activityTypeIDs`,
            message: t("labels.required"),
          });
        }
      }),
    resources: yup.array().test(
      "activityTypes",
      t("labels.required"),

      (val, context) => {
        const { activityTypes } = context.parent;
        if (
          activityTypes &&
          activityTypes.length > 0 &&
          activityTypes[0]?.settings?.allowSelectResource?.toString() === "true"
        ) {
          if (val.length === 0) {
            return context.createError({ message: t("labels.required") });
          } else {
            return true;
          }
        } else {
          return true;
        }
      }
    ),
  });

  const newExamForm = useForm<ExamDetailsProp>({
    mode: "onSubmit",
    defaultValues: getNewExamDetailsDefaultValues() as ExamDetailsProp,
    //@ts-ignore
    resolver: yupResolver(examSchema),
  });

  const {
    getValues,
    reset: restExam,
    handleSubmit: handleExamCreatedSubmit,
  } = newExamForm;

  const {
    data: getExamByMomentIDData,
    loading: getExamByMomentIDDataLoading,
    refetch: refetchExamByMomentIDData,
  } = useQuery(ExamByMomentIdDocument, {
    variables: {
      momentID: selectedMomentID ? selectedMomentID : "",
    },
    fetchPolicy: "network-only",
    skip: selectedMomentID === "",
  });

  React.useEffect(() => {
    if (
      !getExamByMomentIDDataLoading &&
      getExamByMomentIDData &&
      getExamByMomentIDData.ExamByMomentID
    ) {
      setSelectedMomentExam({
        ...getExamByMomentIDData.ExamByMomentID,
        locationID: getExamByMomentIDData.ExamByMomentID.moment.location.id,
        publicExam:
          (getExamByMomentIDData.ExamByMomentID.moment?.properties &&
            getExamByMomentIDData.ExamByMomentID.moment?.properties[
              "publicExam"
            ]) ||
          false,
        publicExamSeats:
          (getExamByMomentIDData.ExamByMomentID.moment?.properties &&
            getExamByMomentIDData.ExamByMomentID.moment?.properties[
              "publicExamSeats"
            ]) ||
          null,
        resources: getExamByMomentIDData.ExamByMomentID.moment?.resources
          ?.length
          ? getExamByMomentIDData.ExamByMomentID.moment.resources.map(
              (resource) => ({
                resourceID: resource,
                name: "",
              })
            )
          : [],
        activityTypes: getExamByMomentIDData.ExamByMomentID?.activities?.length
          ? getExamByMomentIDData.ExamByMomentID.activities.map(
              (activity) => activity.activityType
            )
          : [],
      });

      setEditTitle(
        `${t("labels.currentExam")}: ${dayjs(
          getExamByMomentIDData.ExamByMomentID.moment.startDate
        ).format("DD/MM/YYYY HH:mm")} ${
          getExamByMomentIDData.ExamByMomentID.moment.location.city
        }`
      );
    } else {
      setSelectedMomentExam(undefined);
      setEditTitle(t("labels.currentExam"));
    }
  }, [getExamByMomentIDDataLoading, getExamByMomentIDData, t]);

  const { data: activityTypeData, loading: activityTypeDataLoading } = useQuery(
    GetActivityTypeDocument,
    {
      variables: {},
      fetchPolicy: "network-only",
    }
  );

  React.useEffect(() => {
    if (
      !activityTypeDataLoading &&
      activityTypeData &&
      activityTypeData.ActivityTypes &&
      activityTypeData.ActivityTypes.length > 0
    ) {
      setPlanningActivityTypes([...activityTypeData.ActivityTypes]);
    } else {
      setPlanningActivityTypes([]);
    }
  }, [activityTypeData, activityTypeDataLoading]);

  const {
    data: examActivityData,
    loading: examActivityLoading,
    refetch: refetchExamOverviewWithFilters,
  } = useQuery(GetExamOverviewWithFiltersDocument, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    errorPolicy: "ignore",

    variables: {
      filter: {
        dateFrom: filterValues.dateFrom,
        dateTill: filterValues.dateTill,
      },
    },
  });

  React.useEffect(() => {
    if (
      !examActivityLoading &&
      examActivityData &&
      examActivityData.ExamOverviewWithFilters &&
      examActivityData.ExamOverviewWithFilters.length > 0
    ) {
      setExamDataList(examActivityData.ExamOverviewWithFilters);
    } else {
      setExamDataList([]);
    }
  }, [examActivityLoading, examActivityData]);

  const handleFilterUpdate = (data: ActivityFilterValues) => {
    setFilterValues(data);
  };
  const handleModalOpenState = async (
    event?: React.SyntheticEvent,
    reason?: string
  ) => {
    if (reason !== "backdropClick") {
      setOpenUnsavedChangesDialog(true);
    }
  };

  const examModalClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason !== "backdropClick") {
      if (openExamModal) {
        restExam(
          {},
          {
            keepDefaultValues: false,
            keepIsSubmitted: false,
            keepErrors: false,
          }
        );
        refetchExamByMomentIDData();
        setSelectedMomentExam(null);
        setSelectedMomentExamID("");
        setEditTitle(t("labels.exam"));

        setOpenUnsavedChangesDialog(false);
      }
      setOpenExamModal(!openExamModal);
    }
  };

  const onMutationComplete = (item) => {
    setSelectedMomentExamID(item.NewExam);
    restExam(null, { keepDefaultValues: false });
    setTimeout(() => {
      setSnackBarMessage(t("feedback.createSuccess"));
      setSnackBar(true);
      setSeverity("success");
      setCreatedNewAddress(false);
      setRelationAddress(null);
      refetchExamByMomentIDData();
    }, 1000);
    refetchExamOverviewWithFilters();
  };

  const [addExam] = useMutation(AddExamDocument, {
    onCompleted: onMutationComplete,
  });

  const [linkOrgToExam] = useMutation(LinkOrganizationToPublicMomentDocument, {
    onCompleted: onMutationComplete,
  });

  const handleExamCreated = (examData: ExamDetailsProp) => {
    if (!_.isEmpty(newExamForm.formState.errors)) {
      setSnackBarMessage(t("feedback.missingValues"));
      setSnackBar(true);
      setSeverity("error");
      return;
    }

    if (
      examData &&
      examData.activityTypes &&
      examData.activityTypes.length > 0
    ) {
      addExam({
        variables: {
          input: {
            publicExam: examData.publicExam,
            publicExamSeats: examData.publicExamSeats,
            activityTypeIDs:
              examData.activityTypes &&
              examData.activityTypes.length > 0 &&
              examData.activityTypes.map((act) => act.activityTypeID),
            startDate: examData.moment.startDate,
            notes: examData.moment.notes,
            locationId: examData.locationID,
            resources:
              examData.resources && examData.resources.length > 0
                ? examData.resources.map((res) => res.resourceID)
                : null,
          },
        },
      });
    } else {
      setSnackBarMessage(t("feedback.activtiesNotFound"));
      setSnackBar(true);
      setSeverity("error");
    }
  };

  const onModalClosed = () => {
    setOpenAddressModal(false);
  };

  const handleClose = () => {
    setSnackBar(false);
  };

  const handleOnError = (errors: any) => {
    if (errors) {
      setSnackBar(true);
      setSeverity("error");
      setSnackBarMessage(t("feedback.missingValues"));
    }
  };
  const onSubmit = () => {
    if (
      addOrganisationAddressRef &&
      addOrganisationAddressRef.current &&
      (addOrganisationAddressRef.current as { onSubmit: () => void })?.onSubmit
    ) {
      (
        addOrganisationAddressRef.current as { onSubmit: () => void }
      ).onSubmit();
    }
  };
  const handleOnCreateComplete = (returnAddress: {
    data?: { AddAddress?: any };
  }) => {
    if (returnAddress?.data?.AddAddress) {
      restExam(
        { ...getValues(), locationID: returnAddress.data.AddAddress.id },
        { keepDefaultValues: false }
      );
      setRelationAddress(returnAddress.data.AddAddress);
      setCreatedNewAddress(true);
      setOpenAddressModal(false);
      setSnackBarMessage(t("feedback.createSuccess"));
      setSnackBar(true);
      setSeverity("success");
    }
  };

  const handleSearchTextChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event === null) {
      setExamDataList(examActivityData.ExamOverviewWithFilters);
    } else {
      setExamDataList(
        examActivityData.ExamOverviewWithFilters.filter(
          (item) =>
            item.moment.location.city
              .toLowerCase()
              .includes(event.target.value.toLowerCase()) ||
            item.moment.location.street
              .toLowerCase()
              .includes(event.target.value.toLowerCase()) ||
            item.activity.find((act) =>
              act.name.toLowerCase().includes(event.target.value.toLowerCase())
            )
        )
      );
    }
  };

  const handleOnLinKPublicExamClicked = (rowItem: PublicExamOverviewRow) => {
    linkOrgToExam({
      variables: {
        organizationID: localAppState?.userData?.organization?.id,
        // examID: rowItem.examID,
        momentID: rowItem.momentID,
      },
    }).then(() => {
      setSnackBarMessage(t("feedback.linkSuccess"));
      setShowPublicExamModal(false);
      setSelectedMomentExamID(rowItem.momentID);
      setOpenExamModal(true);
      setPublicExam(null);
    });
  };

  return (
    <Stack
      width="100%"
      id="ActivityOverviewComposite"
      padding={4}
      spacing={4}
      maxWidth={1200}
    >
      <ActivityFilterComponent
        onNewPressed={examModalClose}
        isActivityOverview={true}
        onSearchChanged={handleSearchTextChange}
        inputValues={filterValues}
        onPublicExamPressed={() => setShowPublicExamModal(true)}
        isNotCandidateHistory={true}
        onDataChanged={handleFilterUpdate}
      />
      <Stack
        height={{ md: windowSize.height - 180, xs: windowSize.height - 300 }}
        spacing={2}
        width="100%"
        overflow={"auto"}
      >
        {!examActivityLoading &&
          examActivityData &&
          examDataList &&
          examDataList.length > 0 &&
          examDataList.length > 0 &&
          examDataList.map(
            (
              item: CbexPortalExamOverviewWithFilterOptionsRow,
              index: number
            ) => (
              <ActivityCard
                refetch={refetchExamOverviewWithFilters}
                filterVar={filterValues}
                isActivity={true}
                isResultsCollected={
                  item &&
                  item.status &&
                  item.status.toString().toLowerCase() === "resultscollected"
                    ? true
                    : false
                }
                onExamPressed={(outGoingMomentID) => {
                  setSelectedMomentExamID(outGoingMomentID);
                  setOpenExamModal(true);
                }}
                inputData={item}
                key={index}
              />
            )
          )}
      </Stack>

      {openExamModal && (
        <CustomModal
          openModal={openExamModal}
          closeHandler={
            selectedMomentID === "" ? examModalClose : handleModalOpenState
          }
          modalTitle={selectedMomentID === "" ? t("common.newExam") : editTitle}
          maxHeight={windowSize.height - 150}
          minHeight={windowSize.height - 150}
          minWidth={1250}
        >
          <Stack padding={1}>
            <Stack justifyContent={"space-between"} direction={"row"} p={2}>
              {selectedMomentID === "" &&
                activityConfig &&
                activityConfig[0] &&
                //@ts-ignore
                activityConfig[0].settings &&
                //@ts-ignore
                activityConfig[0].settings.allowNewLocation &&
                !createdNewAddress && (
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                      setOpenAddressModal(true);
                    }}
                    sx={{ width: 200, height: 30 }}
                  >
                    {t("common.newLocation")}
                  </Button>
                )}
              <Stack>{/* for button to be at end always */}</Stack>

              {selectedMomentID === "" && (
                <IconButton
                  sx={{ width: 15 }}
                  type="submit"
                  onClick={handleExamCreatedSubmit(
                    () => handleExamCreated(getValues()),
                    (err: any) => handleOnError(err)
                  )}
                >
                  <SaveIcon color="primary" />
                </IconButton>
              )}
            </Stack>

            {selectedMomentID === "" && (
              <form>
                <NewExamForm
                  form={newExamForm}
                  onActivityChange={(val) => setactivityConfig(val)}
                  newAddresCreated={createdNewAddress}
                  relationAddress={relationAddress}
                />
              </form>
            )}
            {selectedMomentID !== "" && selectedMomentExam && (
              <ExamDetailsComposite
                refetchOverviewData={() => {
                  refetchExamByMomentIDData();
                  refetchExamOverviewWithFilters();
                }}
                examData={selectedMomentExam}
              />
            )}
          </Stack>
        </CustomModal>
      )}

      {snackBar && (
        <SnackBar
          openState={snackBar}
          severity={severity}
          message={snackBarMessage}
          onClose={handleClose}
        ></SnackBar>
      )}

      {openAddressModal && (
        <CustomModal
          modalTitle={t("common.addNewAddress")}
          openModal={openAddressModal}
          minWidth={600}
          closeHandler={onModalClosed}
        >
          <>
            <Stack alignItems={"center"} spacing={2} padding={1}>
              <CreateRelationAddressForm
                addressID=""
                onCreateComplete={handleOnCreateComplete}
                ref={addOrganisationAddressRef}
              />
            </Stack>
            <Stack alignItems="flex-end">
              <Button color="primary" variant="contained" onClick={onSubmit}>
                {t("common.submit")}
              </Button>
            </Stack>
          </>
        </CustomModal>
      )}

      {showPublicExamModal && (
        <CustomModal
          modalTitle={t("common.publicExam")}
          openModal={showPublicExamModal}
          minWidth={600}
          closeHandler={() => setShowPublicExamModal(false)}
        >
          <>
            <Stack alignItems={"center"} spacing={2} padding={1}></Stack>
            <Stack alignItems="flex-end">
              <Button
                color="primary"
                variant="contained"
                disabled={!publicExam}
                onClick={() => handleOnLinKPublicExamClicked(publicExam)}
              >
                {t("common.submit")}
              </Button>
            </Stack>
            <PublicExamsOverview
              onRowClicked={(item) => {
                setPublicExam(item);
              }}
            />
          </>
        </CustomModal>
      )}

      {openUnsavedChangesDialog && (
        <CustomDialog
          open={openUnsavedChangesDialog}
          leftButtonHeading={t("labels.cancel")}
          rightButtonHeading={t("common.confirm")}
          handleCancel={() => {
            setOpenUnsavedChangesDialog(false);
          }}
          handleClosePressed={examModalClose}
          heading={t("feedback.closeConfirm")}
        />
      )}
    </Stack>
  );
};

export default ActivityOverviewComposite;
